import React, {useState} from "react";
import  { useNavigate } from 'react-router-dom';
import { placeorder, sendMessage } from "../api/actions";
import OtpInput from 'otp-input-react';
import '../assets/css/App.css';

import { TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox, Modal, Box, InputAdornment, InputLabel, Select, FormControl } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Form, Formik,useFormik } from 'formik';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { getIn } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { storedroplocation, storeOrdderid } from "../redux/Actions";
import { StoreDropLocation } from "../redux/Reducers";
import CircularProgress from '@mui/material/CircularProgress';


 function Home() {
      const [open, setOpen] = useState(false);
      const [clientotp, setclientotp] = useState('')
      const [otp, setOtp] = useState('');
      const [loading, setloading] =useState(false)
      // const [checkplacerror, setcheckplaceerror] = useState(false)
      const [invalidOtp, setinvalidOtp] = useState(false)
      const [submitbtn, setsubmitbtn] =useState(true)  
      const [mobileVerified, setmobileVerified] = useState(false)
       const dispatch =useDispatch()
      const [age, setAge] = React.useState('');

      const handleChange = (event) => {
        setAge(event.target.value);
      };

      const navigate = useNavigate();
      const validationSchema = yup.object({
        phoneNo:yup.string()
                  .required('Please fill Phone No'), 
        droplocation: yup.string()
                        .required('Please Select Drop Location'), 
        // time: yup.string()    
        //         .required('Please Choose Time'), 
        name:yup.string()
                .required('Please Enter Name'), 
        flightnumber:yup.string()
                  .required('Please Enter Flight Number'), 
        // instantDelivery:yup.boolean().required()
      });
      function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    const formik = useFormik({
        initialValues: {
          phoneNo: '',
          droplocation: '',
          name:'',
          flightnumber:'', 
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
          setloading(true)
          var orderid = randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
          dispatch(storeOrdderid(orderid))
          dispatch(storedroplocation(values.droplocation))
          console.log('Values', values)
          placeorder(values, orderid)
          .then((res)=>{
             console.log('PLaceorder response',res)
             
             setTimeout(()=>{
              navigate(`/order`)  
              setloading(false)
             }, 2000)
             
          })
          .error((err)=>{
            console.log('Placeorder Error', err)
            navigate(`/Error`)  
          }) 
       
        ;    
        },
      });

      const style = {
        position: 'absolute',
        top: '80%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '20px'
      }
      const onValueChange = (value) => {
       formik.setFieldValue('phoneNo',value)
      };
      // const notifysuccess = () => toast("Valid Otp");
      // function notifyfailed ()
      // { 
      //   toast.error("Please Enter Contact Number")
      // }
  return (
    <>
    <>
    {loading ? <div className="Loading-mainWhole-wrapper"> 
     <Box sx={{ display: 'flex' }}>
       <CircularProgress/>
    </Box>
    </div>:null}
    </> 
    <div className="overlay"><div className="overlay-inner"
    style={{
      top: '120px',
      bottom: '73px',
      left: '20px',
      right: '20px',
      zIndex: '1',
      position: 'absolute',
      background: 'white',
      borderRadius: '5px',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)'
    }}
    > 
    {/* {console.log('instantDelivery', formik.values.instantDelivery)} */}
     <form onSubmit={formik.handleSubmit}>  
      <FormGroup style={{ margin: '20px',marginTop: '3px', fontSize: '12px' }}>
        <p className="Homepage-heading-text-p"> Please book your robot for delivery</p>
          
          {/* <TextField
          id="standard-size-normal"
          fullWidth
          label="Name"
          variant="standard" /><br /> */}
   
       <div className="HomePage-Phone-input-wrapper">
          <div
            className="inline HomePage-Phone-input-wrapper-inner-div-one">
            <span className="Homepage-Fields-label-phone"> Enter Phone Number <sup className="Important-textfields-fields-sub"> *</sup></span>
            <PhoneInput
              className="HomePage-Phone-input-wrapper"
              style={{marginBottom: '10px'}}
              country={'it'}
              specialLabel='Phone Number'
              enableSearch={true}
              autoFocus={true}
              value={formik.values.phoneNo}
              onChange={(value)=>onValueChange(value)}
              margin="standard"
              name="phoneNo"
              id="Phone-input"
              // placeholder="Phone Number"
              // className="phone-number-verification"
              required
              containerStyle={{}}
              inputStyle={{
                border: 'solid 1px',
                borderTop: '0',
                borderRight: '0',
                borderLeft: '0',
                borderRadius: '0',
                width: '143%',
                borderBottom:'2px solid #c2c2c2', 
              }}
              dropdownStyle={{ }} 
              error={formik.touched.phoneNo &&
                Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
            />
        
          </div>
       <div className="inline-verify-button-wrapper">
          {mobileVerified ? 
          <Button
          variant="text"
          style={{
          }}
          className="HomePage-inline-verify-button-main"
        >
     <DoneOutlinedIcon className="Homepage-phoneInput-righticon"/>
     {/* {console.log('otp2',otp)} */}
        </Button>
           
            :<Button
              variant="text"
              onClick={() => {
               let message= Math.floor(100000 + Math.random() * 900000)
               if(formik.values.phoneNo!== ''){ 
                setOpen(true)
                sendMessage(formik.values.phoneNo, message)
                .then((res)=>{
                   console.log('Send Message res', res)
                   setclientotp(message)
                                })
                .catch((err)=>{
                  console.log('Send Message err', err)
                })
              }
              else{
                // notifyfailed()
                alert('Please Enter a Valid Phone number')
              }
              }
            }
              className="HomePage-inline-verify-button-main"
            >
              Verify
            </Button>}
            </div>
        </div>
        {formik.errors.phoneNo && formik.touched.phoneNo? <p className="Validation-error-p">Please Enter a Phone and Verify It.</p>:null}
              
            <div className="Fields-wrapper-Homme">
              <span className="Homepage-Fields-label">Select Delivery Location <sup className="Important-textfields-fields-sub"> *</sup></span>  
              <FormControl className="HomePage-inputfields-wrapper">
              {/* <InputLabel id="demo-simple-select-label" style={{fontWeight:'500', fontSize:"13px" , color:'#c2c2c2', marginLeft:'0rem', marginTop:".5rem"}}>Drop Location</InputLabel> */}
             
              {/* <div style={{ marginTop: '10px',display:'flex', justifyContent:'', alignItems:'center',}}> 
              <span className="Inputfield-icons" style={{marginBottom:'10px'}}>  <LocationOnOutlinedIcon style={{color:'rgb(161 161 161 / 60%)'}}/></span>  */}
                  <TextField
                  className="px-2 HomePage-inputfields-main"
                  variant="outlined"
                  name="droplocation"
                  id="droplocation"
                  select
                  displayEmpty
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      <img src={require('../assets/images/location-icon.png')} className="Homepage-textfields-image"/>
                      </InputAdornment>
                    ),
                  }} 
                  value={formik.values.droplocation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.droplocation &&
                    Boolean(formik.errors.droplocation)
                  }
                  helperText={
                    formik.touched.droplocation && formik.errors.droplocation
                  }
                > 
          
               <MenuItem key={""} value="GATE_A61" style={{fontFamily: 'Poppins,sans-serif'}} >
               GATE_A61

                </MenuItem>
                <MenuItem key={""} value={"GATE_A62"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A62

                </MenuItem>
                <MenuItem key={""} value={"GATE_A63"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A63

                </MenuItem>
                <MenuItem key={""} value={"GATE_A64"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A64
                </MenuItem>
                <MenuItem key={""} value={"GATE_A65"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A65
                </MenuItem>
                <MenuItem key={""} value={"GATE_A66"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A66
                </MenuItem>
                <MenuItem key={""} value={"GATE_A67"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A67
                </MenuItem>
                <MenuItem key={""} value={"GATE_A68"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A68
                </MenuItem>
                <MenuItem key={""} value={"GATE_A69"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A69
                </MenuItem>
                <MenuItem key={""} value={"GATE_A70"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A70
                </MenuItem>
                <MenuItem key={""} value={"GATE_A71"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A71
                </MenuItem>
                <MenuItem key={""} value={"GATE_A72"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A72
                </MenuItem>
                <MenuItem key={""} value={"GATE_A73"} style={{fontFamily: 'Poppins,sans-serif'}} >
                GATE_A73
                </MenuItem>
            </TextField>
              </FormControl>  
              </div> 

                    <br />

       
        <div className="Fields-wrapper-Homme">
        <span className="Homepage-Fields-label">Enter Name</span>  
        <TextField
              id="standard-size-normal"
              fullWidth
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                <img src={require('../assets/images/name-icon.png')} className="Homepage-textfields-image"/>
                  </InputAdornment>
                ),
              }}
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              // placeholder="Name"
              error={
                formik.touched.name &&
                Boolean(formik.errors.name)
              }
              helperText={
                formik.touched.name && formik.errors.name
              }
              className="HomePage-inputfields-main"
             />
             </div>
            {/* </div>  */}
            {/* {formik.errors.name && formik.touched.name? <p style={{color:'#d32f2f',fontSize:'0.75rem'}}>Please Enter Name</p>:null} */}
             <br />
          
             <div className="Fields-wrapper-Homme">
            <span className="Homepage-Fields-label">Enter Flight Number </span>
            <TextField
              id="standard-basic"
              fullWidth
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                   <img src={require('../assets/images/flight-icon.png')} className="Homepage-textfields-image"/>
                  
                  </InputAdornment>
                ),
              }}
              value={formik.values.flightnumber}
              onChange={formik.handleChange}
              // placeholder="Flight Number"
             
              name="flightnumber"
              error={
                formik.touched.flightnumber &&
                Boolean(formik.errors.flightnumber)
              }
              helperText={formik.touched.flightnumber && formik.errors.flightnumber}
           
              className="HomePage-inputfields-main"
             />
              </div>
             {/* </div>  */}
             {/* {formik.errors.flightnumber && formik.touched.flightnumber? <p style={{color:'#d32f2f',fontSize:'0.75rem'}}>Please Enter Flightnumber</p>:null} */}
              <br />
        <div>

   
        </div>

       <div className="Homepage-submit-button" > 
            <Button
          
             type="submit"
              variant="contained"
              // onClick={() => {
              //   navigate('/form')
              // }}
              className={submitbtn ? "submit-button-Homepage" : "submit-button-Homepage-active"}
              disabled={submitbtn}
            >
              <text className="Homepage-submit-button-text"
              >SUBMIT</text>
            </Button>
        </div>  
      </FormGroup>
      </form>
    </div>
  
    <div
      className="footer-logo"
      style={{
     
      }}
    >
        <img
          src={require('../assets/images/logo.png')}
          alt={'pic'}
          className="Footer-logo-image"
   />
      </div>

      <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: '-3px',
            fontSize: '11px',
          }}
        >
          Powered by<br />
          <img
            src={require('../assets/images/ottonomy-logo-b.png')}
            alt={'logo'}
            className="store-logo"
            style={{
              // display: "block",
              // marginLeft: 'auto',
              // marginRight: 'auto',
              width: '40%'
            }} />
        </div>
      
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
        <div className="Footer-div-bottom-wrapper"
          style={{
            background: '#004995',
            display: 'block',
            width: '130px',
            height: '130px',
            margin: 'auto',
            marginTop: '-100px',
            borderRadius: '50%'
          }}
        >
          <img
          src={require('../assets/images/verify-otp.png')}
          alt={'verify-otp'}
          style={{
            width: '50%',
            marginLeft: '35px',
            marginTop: '35px'
          }} />
        </div>

            <p
              style={{
                color: '#004995',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '15px',
                textAlign: 'center'
              }}
            >VERIFY OTP</p>
            <OtpInput 
            inputStyles={{display: 'block', margin: 'auto'}} 
            value={otp} 
            onChange={setOtp} 
            autoFocus 
            OTPLength={6} 
            otpType="number" 
            disabled={false} 
           />
           
            <Button
              variant="contained"
              onClick={() => {
              let clotp = Number(clientotp)
              console.log('clientotp', clotp, 'otp', otp)
                if(clotp == otp){
                setOpen(false) 
                // notifysuccess() 
                setsubmitbtn(false)
                setmobileVerified(true) 
              }
               else{
                setinvalidOtp(true)  
                }
               
              }}
              style={{
                size: "large",
                display: "block",
                borderRadius: "25px",
                margin: 'auto',
                marginTop: '30px',
                height: '50px',
                backgroundColor: 'rgb(0,73,148)'
              }}
              fullWidth
            >
              <text
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >VERIFY</text>
            </Button>
            {invalidOtp ? <p style={{color:'#d32f2f', fontSize:'0.75rem'}}>Please Enter Valid OTP </p> :null} 
        </Box>
      </Modal>
      <ToastContainer />
      
    </div>
    </>  
  );
}

export default Home;
