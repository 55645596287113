import React from "react";
import '../assets/css/App.css';
import { TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox, Modal, Box, InputAdornment, InputLabel, Select, FormControl } from '@mui/material';
import { DoorSlidingSharp } from '@mui/icons-material';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { Form, Formik,useFormik } from 'formik';
import * as yup from 'yup';
import  { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import OtpInput from 'otp-input-react';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { sendMessage, updateFleet } from "../api/actions";
import EmailIcon from '@material-ui/icons/Email';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

function Getnotify() {
  const [open, setOpen] = useState(false);
  const [opennotify, setopennotify] = useState(false);
  const [otp, setOtp] = useState('');
  const [mobileVerified, setmobileVerified] = useState(false)
  const validationSchema = yup.object({
   email:yup.string()
            .required('Please enter Email')
            .email('Enter a valid email'),
   phoneNo:yup.string()
            .required('Please Enter PhoneNo'), 

  });
  const navigate=useNavigate()
  const style = {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px'
  }
  const styletwo = {
    position: 'absolute',
    top: '75%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px'
  }
  const formik = useFormik({
    initialValues: {
        email:'',
      phoneNo:''    
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Values', values)
      setopennotify(true)
    },
  })

  const onValueChange = (value) => {
    formik.setFieldValue('phoneNo',value);
   };
  return (
    <div className="overlay"><div className="overlay-inner"
      style={{
        top: '120px',
        bottom: '75px',
        left: '20px',
        right: '20px',
        zIndex: '1',
        position: 'absolute',
        background: 'white',
        borderRadius: '5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        overflow:'auto',
        padding:'20px'
      }}
    >
         <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#004994',
            marginTop: '10px',
            marginBottom: '0px'
          }}
        >Hi, Good Morning</p>
      <p style={{marginTop:'10px', fontSize:'14px', lineHeight: '20px'}}> Please fill the below details for the Notification when the Ottobot will return</p>  
      <form onSubmit={formik.handleSubmit}>  
      <FormGroup style={{ margin: '5px 0px 20px',marginTop: '5px', fontSize: '18px' }}>
      <div style={{marginTop: '5px',display:'flex', justifyContent:'', alignItems:'center', margin:'13px'
            //  borderBottom:'2px solid rgb(161 161 161 / 60%)'
             }}>
             <span className="Inputfield-icons">  <EmailIcon style={{color:'rgb(161 161 161 / 60%)'}}/></span>  
            <TextField
              id="standard-basic"
              fullWidth
              email
              variant="standard"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <FlightTakeoffIcon/>
              //     </InputAdornment>
              //   ),
              // }}
              value={formik.values.email}
              onChange={formik.handleChange}
              label='Enter Email'
              name="email"
              error={
                formik.touched.email &&
                Boolean(formik.errors.email)
              }
              helperText={formik.touched.email && formik.errors.email}
              style={{ marginTop: '10px'}}
              />
             </div> 
      <div style={{margin: '10px'}}> 
        {/* <text
          style={{
            fontWeight: 'bold',
            marginLeft: '5px',
            marginBottom: '10px'
          }}
        >
          Phone Number
        </text> */}

        <div style={{margin:'5px',marginBottom: "17px",display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
          <div
            className="inline"
            style={{
              width: '70%'
            }}
          >
          <PhoneInput
              style={{marginBottom: '10px'}}
              country={'us'}
              specialLabel='Phone Number'
              enableSearch={true}
              autoFocus={true}
              value={formik.values.phoneNo}
              onChange={(value)=>onValueChange(value)}
              margin="standard"
              name="phoneNo"
              // placeholder="Phone Number"
              // className="phone-number-verification"
              required
              containerStyle={{}}
              inputStyle={{
                border: 'solid 1px',
                borderTop: '0',
                borderRight: '0',
                borderLeft: '0',
                borderRadius: '0',
                width: '143%'
              }}
              dropdownStyle={{ }} 
              error={formik.touched.phoneNo &&
                Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
            />
          </div>
       
          <div className="inline">
          {mobileVerified ? 
          <Button
          variant="text"
          style={{
            fontWeight: 'bold',
            fontSize: '15px',
            textTransform: 'capitalize',
            color:'rgb(0,73,148)'
          }}
        >
     <DoneOutlinedIcon style={{color:"green" , zIndex:'9999999'}}/>
     
        </Button>
           
            :<Button
              variant="text"
              onClick={() => {
               let message= Math.floor(100000 + Math.random() * 900000)
               if(formik.values.phoneNo!== ''){ 
               sendMessage(formik.values.phoneNo, message)
                .then((res)=>{
                   console.log('Send Message res', res)
                   setOpen(true)
                })
                .catch((err)=>{
                  console.log('Send Message err', err)
                })
              }
              else{
                alert('Please fill mobile number properly')
              }
              }
            }
              style={{
                fontWeight: 'bold',
                fontSize: '15px',
                textTransform: 'capitalize',
                color:'rgb(0,73,148)'
              }}
            >
              Verify
            </Button>}
          </div>
        </div>
        {formik.errors.phoneNo && formik.touched.phoneNo? <p style={{color:'#d32f2f',fontSize:'0.75rem',marginTop: "-17px",marginLeft: '51px'}}>Please Enter a Phone and Verify It.</p>:null}
      </div>
     
      </FormGroup>
      <Button
          variant="contained"
          type="submit"
          style={{
            size: "large",
            display: "block",
            borderRadius: "20px",
            width: "87%",
            padding: '5px 4px',
            margin:'20px',
            backgroundColor:'rgb(0,73,148)',
            marginTop: '10px',
            marginTop: '7rem'
          }}
      
        >
          <text
            style={{
              fontSize: '15px',
              fontWeight: 'bold'
            }}
          >SUBMIT</text>
        </Button>
      </form>
    </div><div
       className="logo"
      style={{
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'lighter',
      }}
    >
        <img
          src={require('../assets/images/logo.png')}
          alt={'pic'}
          style={{
            // display: "block",
            // marginLeft: 'auto',
            // marginRight: 'auto',
            marginTop: '30px',
            width: '60%'
          }} />

      </div>
      <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: '0px'
          }}
        >
          Powered by<br />
          <img
            src={require('../assets/images/ottonomy-logo-b.png')}
            alt={'logo'}
            className="store-logo"
            style={{
              // display: "block",
              // marginLeft: 'auto',
              // marginRight: 'auto',
              width: '50%'
            }} />
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
        <div
          style={{
            background: '#004995',
            display: 'block',
            width: '130px',
            height: '130px',
            margin: 'auto',
            marginTop: '-100px',
            borderRadius: '50%'
          }}
        >
          <img
          src={require('../assets/images/verify-otp.png')}
          alt={'verify-otp'}
          style={{
            width: '50%',
            marginLeft: '35px',
            marginTop: '35px'
          }} />
        </div>

            <p
              style={{
                color: '#004995',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '15px',
                textAlign: 'center'
              }}
            >VERIFY OTP</p>
            <OtpInput inputStyles={{display: 'block', margin: 'auto'}} value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false}  />

            <Button
              variant="contained"
              onClick={() => {
                setOpen(false)
              }}
              style={{
                size: "large",
                display: "block",
                borderRadius: "25px",
                margin: 'auto',
                marginTop: '30px',
                height: '50px',
                backgroundColor: '#004995'
              }}
              fullWidth
            >
              <text
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >VERIFY</text>
            </Button>
        </Box>
      </Modal>
      <Modal
        open={opennotify}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styletwo}>
        
        <div
          style={{
            background: '#004995',
            display: 'block',
            width: '130px',
            height: '130px',
            margin: 'auto',
            marginTop: '-100px',
            borderRadius: '50%'
          }}
        >
          <img
          src={require('../assets/images/noti.png')}
          alt={'noti.png'}
          style={{
            width: '50%',
            marginLeft: '35px',
            marginTop: '35px'
          }} />
        </div>

            <p
              style={{
                color: '#004995',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '15px',
                textAlign: 'center'
              }}
            >Thank you</p>
           <p style={{fontSize:'19px',textAlign:'center'}}>You Will be notify when the ottobot is wiil return to its location </p>

            <Button
              variant="contained"
              onClick={() => {
                setopennotify(false)
              }}
              style={{
                size: "large",
                display: "block",
                borderRadius: "25px",
                margin: 'auto',
                marginTop: '30px',
                height: '50px',
                backgroundColor: '#004995'
              }}
              fullWidth
            >
              <text
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >Close</text>
            </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Getnotify;
