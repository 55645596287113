import React, { useEffect, useState } from "react";
import '../assets/css/App.css';
import { fleetStatus } from "../api/actions";

function Fleet() {

  const [update, setUpdate] = useState('');

  useEffect(() => {

    fleetStatus({
      "fleetId": process.env.REACT_APP_FLEET_ID
    }).then((response) => {
      setUpdate(response);
    }).catch((error) => {
      alert(error);
    });

  }, []);

  return (
    <div className="overlay"><div
      style={{
        top: '120px',
        bottom: '100px',
        left: '20px',
        right: '20px',
        zIndex: '1',
        position: 'absolute',
        background: 'white',
        borderRadius: '5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)'
      }}
    >
      
      <p style={{ margin: '20px' }}>
            <p
                style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: 'blue'
                }}
            >Fleet Status</p>

            <p>{update}</p>

        </p>

    </div><div
       className="logo"
      style={{
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'lighter',
      }}
    >
        <img
          src={require('../assets/images/logo.png')}
          alt={'pic'}
          style={{
            // display: "block",
            // marginLeft: 'auto',
            // marginRight: 'auto',
            marginTop: '30px',
            width: '60%'
          }} />

      </div>
      <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: '20px'
          }}
        >
          Powered by<br />
          <img
            src={require('../assets/images/ottonomy-logo-b.png')}
            alt={'logo'}
            className="store-logo"
            style={{
              // display: "block",
              // marginLeft: 'auto',
              // marginRight: 'auto',
              width: '50%'
            }} />
      </div>
    </div>
  );
}

export default Fleet;
