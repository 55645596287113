import { useDispatch } from 'react-redux';
import Order from '../components/Order';
import { AppContext } from '../context/AppContext';
import axios from './axios';

export const fleetStatus = async (fleetId) => {
    var config = {
        method: 'post',
        url: process.env.REACT_APP_FLEET_URL,
        headers: { 
          'Authorization': process.env.REACT_APP_FLEET_AUTH, 
          'Content-Type': 'application/json', 
          'Cookie': process.env.REACT_APP_FLEET_COOKIE
        },
        data: JSON.stringify({
            fleetId
        })
    };
    
    return axios(config).then((response) => {
        return JSON.stringify(response.data.message);
    }).catch((error) => {
        return error;
    })
}
const token='eyJhbGciOiJIUzI1NiJ9.ImhhcmRpay5zaGFybWFAb3R0b25vbXkuaW8i.N444LHWUraQKa56oIHYHTyksEVA--pWsmdizhTa5QSQ'
// export const checkfleet=  ()=>{
//  return axios.post(`${process.env.REACT_APP_BASE_URL}fleet/v1/checkFleetAvailability`,{
//     fleetId: "61a13fb899f8c174061ca6e5"
//   },{  headers:{
//             'Authorization':`Bearer ${token}`,
//             'Content-Type': 'application/json', 
//         }
//     })
//         .then((response)=>{
//             console.log('Check Fleet response', response)
//            return response
//          })
//          .catch((error)=>{
//           console.log('Fleet Id Error', error)
//           return error
//          })
// } 

export const placeorder=  (values, orderid)=>{
   

    return axios.post(`${process.env.REACT_APP_BASE_URL}order/v1/placeOrder`,{
        orderId: orderid,
        fleetId:"6321a5aa84fae9395c30eae6",
        customerName:values.name,
        customerMobileNumber:values.phoneNo,
        customerEmail:values.flightnumber,
        orderDetails:values.flightnumber,
        orderValue:'999',
        pickupId: "AELIA",
        dropId: values.droplocation,
        qrcodePickup:'AS223',
        qrcodeDrop: 'AS223',
        timeout: "180",
        timeoutBeh: "1000",
        deadline:"1000"
    }, {
           headers:{
               'Authorization':`Bearer ${token}`,
               'Content-Type': 'application/json', 
           }
       })
            .then((response)=>{
               console.log(' Placeorder response', response ,values)
              return response
            })
            .catch((error)=>{
             console.log('placeorder Error', error)
             return error
            })
   } 
   
   export const updateFleet=(values)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}fleet/v1/updateFleet`,{
        fleetId:"6321a5aa84fae9395c30eae6",
        fleetName:"ottonomy",
        location:values.newgate,
        adminName:"hardik",
        adminEmail:"hardik.sharma@ottonomy.io",
        adminContactNumber:values.phoneNo,
        imageLogoUrl:"https://cloudbucketottonomy.s3.ap-south-1.amazonaws.com/demo.png",
        timezone: "timezone"
    }, {
           headers:{
               'Authorization':`Bearer ${token}`,
               'Content-Type': 'application/json', 
           }
       })
            .then((response)=>{
               console.log(' Update Fleet response', response)
              return response
            })
            .catch((error)=>{
             console.log('Update Fleet Error', error)
             return error
            })
   }

   export const sendMessage=(phoneNo,message)=>{
   return axios.post(`https://hammerhead.ottonomy.io/order/v1/sendMessage`,{
    number:`+${phoneNo}`,
    message:`Your OTP verification code is:${message}`
   }, {
    headers:{
        'Authorization':`Bearer ${token}`,
        'Content-Type': 'application/json', 
    }
})   .then((response)=>{
            console.log('Send Message response', response)
        return response
        })
        .catch((error)=>{
        console.log('Send Message Error', error)
        return error
        })

   }