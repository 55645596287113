import React, { useContext } from "react";
import '../assets/css/App.css';

import { TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox, InputAdornment, Modal, Box } from '@mui/material';
import { DoorSlidingSharp, Autorenew } from '@mui/icons-material';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { useEffect } from "react";
import { useState } from "react";
import $ from 'jquery'
import { useSelector } from "react-redux";


function Order(props) {

  useEffect(()=>{
    generateBarCode()
  },[])

  const orderID = useSelector(state=>state.StoreOrderId)
  const dropLocation = useSelector(state=>state.StoreDropLocation)
  function generateBarCode()
  {
      // var nric = $('#text').val();
      var rString = randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'); 
      var url = 'https://api.qrserver.com/v1/create-qr-code/?data=AS223&amp;size=50x50';
      $('#barcode').attr('src', url);
     
      console.log('rString',rString)
     }
  function randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
  }

  const robotId = "ROR 001";
  const [open, setOpen] = useState(false);
  const style = {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px',
    outline: 'none !important'
  };
  useEffect(()=>{
    setTimeout(() => {
      setOpen(false)
    }, 5000);
  })
  return (
    <div className="overlay"><div className="overlay-inner"
      style={{
        top: '120px',
        bottom: '73px',
        left: '20px',
        right: '20px',
        zIndex: '1',
        position: 'absolute',
        background: 'white',
        borderRadius: '5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)'
      }}
    >
       {console.log('dropLocation.DropLocation',dropLocation.DropLocation)}
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'rgb(0,73,148)',
            marginTop: '15px',
            marginBottom: '15px',
            textAlign: 'center'
          }}
        >Order Details</p>

        <div
            style={{
                backgroundColor: 'rgb(240,240,240)',
                textAlign: 'center'
            }}
        >
          <div className="inline"
            style={{
                padding: '8px 0px',
                width: '50%',
                fontSize:'12px',
                lineHeight: '16px'
            }}
          >
            Order Id <br />
            <text
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}
            >{orderID.orderID}</text>
          </div>
          
          <div className="inline"
            style={{
                padding: '8px 0px',
                width: '50%',
                fontSize:'12px',
                lineHeight: '16px'
            }}
          >
            Robot Assigned <br />
            <text
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}
            > Ottobot</text>  
          </div>
        </div>
        <div
            style={{
                backgroundColor: 'rgb(240,244,249)',
                textAlign: 'center'
            }}
        >
          <div className="inline"
            style={{
                padding: '8px 0px',
                width: '50%',
                fontSize:'12px',
                lineHeight: '16px'
            }}
          >
            PickUp Location <br />
            <text
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}
            >Aelia Duty Free</text>
          </div>
          
          <div className="inline"
            style={{
                padding: '8px 0px',
                width: '50%',
                fontSize:'12px',
                lineHeight: '16px'
            }}
          >
           Drop Location <br />
            <text
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold'
                }}
            > {dropLocation.DropLocation}</text>  
          </div>
        </div>
        <p
            style={{
                textAlign: 'center',fontSize: '13px',margin: '15px'}}
        >Please use the below QR code to scan and open the cabin to collect your order</p>

        {/* <Button
          variant="contained"
          onClick={() => {
            
          }}
          style={{
            size: "large",
            borderRadius: "30px",
            display: 'block',
            margin: 'auto',
            width: '60%',
            height: '10%',
            marginBottom: '20px'
          }}
          disabled
        >
          <text
            style={{
              fontSize: '15px',
              fontWeight: 'bold'
            }}
          >COLLECT ORDER</text>
        </Button> */}
        
        {/* <img
          src={require('../assets/images/map.png')}
          alt={'map'}
          style={{
            width: '80%',
            display: 'block',
            margin: 'auto'
          }} /> */}
       <div className="OR-CodeWrapper">      
         <img id='barcode' 
            src="https://api.qrserver.com/v1/create-qr-code/?data=Congratulation&amp;size=100x100" 
            alt="" 
            title="Congratualtion" 
            width="250" 
            height="250"
            style={{objectFit:'cover', margin:'auto'}} />
        </div>
        {/* <a 
            href="/gate"
            style={{
                fontWeight: 'bolder',
                display: 'block',
                textAlign: 'center',
                marginTop: '15px',
                color:'rgb(0,73,148)'
            }}    
        >CHANGE DELIVERY LOCATION</a> */}

    </div><div
       className="footer-logo"
      style={{
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'lighter',
      }}
    >
        <img
          src={require('../assets/images/logo.png')}
          alt={'pic'}
          style={{
            // display: "block",
            // marginLeft: 'auto',
            // marginRight: 'auto',
            marginTop: '30px',
            width: '60%',
            
          }} 
          className="Footer-logo-image"
          />

      </div>
      <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: '0px',
            fontSize: '11px',
          }}
        >
          Powered by<br />
          <img
            src={require('../assets/images/ottonomy-logo-b.png')}
            alt={'logo'}
            className="store-logo"
            style={{
              // display: "block",
              // marginLeft: 'auto',
              // marginRight: 'auto',
              width: '40%'
            }} />
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
        <div
          style={{
            background: '#004995',
            display: 'block',
            width: '130px',
            height: '130px',
            margin: 'auto',
            marginTop: '-100px',
            borderRadius: '50%'
          }}
        >
          <Autorenew 
            style={{
                color: 'white',
                marginTop: '15px',
                marginLeft: '15px'
            }}
            sx={{ fontSize: 100 }}
          />
        </div>

            <p
              style={{
                color: '#004995',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '15px',
                textAlign: 'center'
              }}
            >UPDATING...</p>

            <p
                style={{
                    fontSize: '20px',
                    textAlign: 'center'
                }}
            >This window will close automatically in 5 seconds and you will be redirecting to order tracking page</p>
            
        </Box>
      </Modal>
    </div>
  );
}

export default Order;
