import React from 'react'
import { Link } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
function Error() {
  return (
    <div className="overlay">
        <div className="Error-page-wrapper">
            <img src={require('../assets/images/sorry.png')} className="searching-page-content-image-class" />
                <h1> 
                    404 Not Found
                 </h1>
                 <Link className="Error-page-wrapper-link" to="/"><span><KeyboardBackspaceIcon/> </span> Go Back To Home</Link>
       </div> 
       </div>
  )
}

export default Error