
const orderID =''
export const StoreOrderId =(state=orderID,action)=>{
    switch(action.type){
        case 'STORE_ORDER_ID': 
        
        return{
            orderID:action.payload
        }   
        default:
            return state;    
    }

}
const DropLocation =''
export const StoreDropLocation =(state=DropLocation,action)=>{
    switch(action.type){
        case 'STORE_DROP_LOCATION': 
        
        return{
            DropLocation:action.payload
        }   
        default:
            return state;    
    }

}