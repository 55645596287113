import React from "react";
import '../assets/css/App.css';
import { TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox, Modal, Box, InputAdornment, InputLabel, Select, FormControl } from '@mui/material';
import { DoorSlidingSharp } from '@mui/icons-material';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { Form, Formik,useFormik } from 'formik';
import * as yup from 'yup';
import  { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import { useState } from "react";
import OtpInput from 'otp-input-react';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import { updateFleet } from "../api/actions";

function Gate() {
  const [open, setOpen] = useState(false);

  const [otp, setOtp] = useState('');
  const validationSchema = yup.object({
    oldgate:yup.string()
              .required('Please Select Old Gate'), 
    newgate : yup.string()
                    .required('Please Select New Gate'), 
    phoneNo:yup.string()
            .required('Please Enter PhoneNo'), 

  });
  const navigate=useNavigate()
  const style = {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '20px'
  }
  const formik = useFormik({
    initialValues: {
      oldgate: '',
      newgate: '',
      phoneNo:'',    
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('Values', values)
                setOpen(true)
                updateFleet(values)
                .then((res)=>{
                   console.log('Update Fleet response',res)
                   setTimeout(()=>{
                   }, 2000)
                })
                .error((err)=>{
                  console.log('Update Fleet Error', err)
                })     
    },
  })

  const onValueChange = (value) => {
    formik.setFieldValue('phoneNo',value);
   };
  return (
    <div className="overlay"><div className="overlay-inner"
      style={{
        top: '120px',
        bottom: '75px',
        left: '20px',
        right: '20px',
        zIndex: '1',
        position: 'absolute',
        background: 'white',
        borderRadius: '5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
        overflow:'auto'
      }}
    >
      <form onSubmit={formik.handleSubmit}>  
      <FormGroup style={{ margin: '20px',marginTop: '5px', fontSize: '18px' }}>
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'rgb(0,73,148)',
            marginTop: '10px',
            marginBottom: '20px'
          }}
        >Change Delivery Gate</p>


        <div style={{margin:'5px',marginBottom: "17px"}}>
        <text
          style={{
            fontWeight: 'bold',
            marginLeft: '5px',
            marginBottom: '10px'
          }}
        >
          Old Gate
        </text>
        <FormControl style={{width:'100%'}}>
  
               {/* <InputLabel id="demo-simple-select-label" style={{fontWeight:'500', fontSize:"13px" , color:'#c2c2c2', marginLeft:'2rem', marginTop:".5rem"}}>Old Gate</InputLabel> */}
             <div style={{ marginTop: '10px',display:'flex', justifyContent:'', alignItems:'center'}}> 
              <span className="Inputfield-icons" style={{marginBottom:'10px'}}>  <DoorSlidingOutlinedIcon style={{color:'rgb(161 161 161 / 60%)'}}/></span> 
              <TextField
              style={{ width: "100%", margin:'5px', marginLeft:'0px' , marginRight:'5px' , textAlign:'center'}}
              className="px-2 my-2 gate-page-input-icon"
              variant="outlined"
              name="oldgate"
              id="oldgate"
              label="Old Gate"
              select
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <LocationOnOutlinedIcon />
              //     </InputAdornment>
              //   ),
              // }} 
              value={formik.values.oldgate}
              onChange={formik.handleChange}
              error={
                formik.touched.oldgate &&
                Boolean(formik.errors.oldgate)
              }
              helperText={
                formik.touched.oldgate && formik.errors.oldgate
              }
            >
               <MenuItem key={""} value="10" style={{textAlign:'center'}}>
                10
                </MenuItem>
                <MenuItem key={""} value={"20"}>
                20
                </MenuItem>
              {/* {governorates.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))} */}
            </TextField>
            </div>
        
              </FormControl>
              </div>  
        {/* <TextField
          className="inputRounded"
          id="outlined-size-normal"
          select
          value={null}
          onChange={''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DoorSlidingSharp />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
        >
          <MenuItem>
            A
          </MenuItem>
          <MenuItem>
            B
          </MenuItem>
          <MenuItem>
            C
          </MenuItem>
        </TextField><br /> */}
     <div style={{margin:'5px',marginBottom: "17px"}}>
        <text
          style={{
            fontWeight: 'bold',
            marginLeft: '5px',
            marginBottom: '10px'
          }}
        >
          New Gate
        </text>

        <FormControl style={{width:'100%'}}>
        <div style={{ marginTop: '10px',display:'flex', justifyContent:'', alignItems:'center'}}> 
              <span className="Inputfield-icons" style={{marginBottom:'10px'}}>  <DoorSlidingOutlinedIcon style={{color:'rgb(161 161 161 / 60%)'}}/></span> 
              <TextField
              style={{ width: "100%", margin:'5px', marginLeft:'0px' , marginRight:'5px' , textAlign:'center'}}
              className="px-2 my-2 gate-page-input-icon"
              variant="outlined"
              name="newgate"
              id="newgate"
              label="New Gate"
              select
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <LocationOnOutlinedIcon />
              //     </InputAdornment>
              //   ),
              // }} 
              value={formik.values.newgate}
              onChange={formik.handleChange}
              error={
                formik.touched.newgate &&
                Boolean(formik.errors.newgate)
              }
              helperText={
                formik.touched.newgate && formik.errors.newgate
              }
            >
               <MenuItem key={""} value="10" style={{textAlign:'center'}}>
                10
                </MenuItem>
                <MenuItem key={""} value={"20"}>
                20
                </MenuItem>
              {/* {governorates.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))} */}
            </TextField>
            </div>
              </FormControl> 
              </div> 

      <div style={{marginTop:'13px'}}> 
        <text
          style={{
            fontWeight: 'bold',
            marginLeft: '5px',
            marginBottom: '10px'
          }}
        >
          Phone Number
        </text>

        <div style={{margin:'5px',marginTop:'17px',marginBottom: "17px",display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
          <div
            className="inline"
            style={{
              width: '70%'
            }}
          >
          <PhoneInput
              style={{marginBottom: '10px'}}
              country={'us'}
              specialLabel='Phone Number'
              enableSearch={true}
              autoFocus={true}
              value={formik.values.phoneNo}
              onChange={(value)=>onValueChange(value)}
              margin="standard"
              name="phoneNo"
              // placeholder="Phone Number"
              // className="phone-number-verification"
              required
              containerStyle={{}}
              inputStyle={{
                border: 'solid 1px',
                borderTop: '0',
                borderRight: '0',
                borderLeft: '0',
                borderRadius: '0',
                width: '143%'
              }}
              dropdownStyle={{ }} 
              error={formik.touched.phoneNo &&
                Boolean(formik.errors.phoneNo)}
              helperText={formik.touched.phoneNo && formik.errors.phoneNo}
            />
          </div>
       
          <div className="inline">
            <Button
              variant="text"
              style={{
                fontWeight: 'bold',
                fontSize: '15px',
                textTransform: 'capitalize',
                color:'rgb(0,73,148)'
              }}
              onClick={()=>{
                setOpen(true)
              }}
            >
              Verify
            </Button>
          </div>
        </div>
        {formik.errors.phoneNo && formik.touched.phoneNo? <p style={{color:'#d32f2f',fontSize:'0.75rem',marginTop: "-17px",marginLeft: '51px'}}>Please Enter a Phone and Verify It.</p>:null}
      </div>
        <Button
          variant="contained"
          type="submit"
          style={{
            size: "large",
            display: "block",
            borderRadius: "20px",
            width: "87%",
            padding: '5px 4px',
            margin:'20px',
            backgroundColor:'rgb(0,73,148)',
            marginTop: '10px'
          }}
      
        >
          <text
            style={{
              fontSize: '15px',
              fontWeight: 'bold'
            }}
          >SUBMIT</text>
        </Button>
      </FormGroup>
      </form>
    </div><div
       className="logo"
      style={{
        fontSize: '15px',
        textAlign: 'center',
        fontWeight: 'lighter',
      }}
    >
        <img
          src={require('../assets/images/logo.png')}
          alt={'pic'}
          style={{
            // display: "block",
            // marginLeft: 'auto',
            // marginRight: 'auto',
            marginTop: '30px',
            width: '60%'
          }} />

      </div>
      <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            bottom: '0px'
          }}
        >
          Powered by<br />
          <img
            src={require('../assets/images/ottonomy-logo-b.png')}
            alt={'logo'}
            className="store-logo"
            style={{
              // display: "block",
              // marginLeft: 'auto',
              // marginRight: 'auto',
              width: '50%'
            }} />
      </div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        
        <div
          style={{
            background: '#004995',
            display: 'block',
            width: '130px',
            height: '130px',
            margin: 'auto',
            marginTop: '-100px',
            borderRadius: '50%'
          }}
        >
          <img
          src={require('../assets/images/verify-otp.png')}
          alt={'verify-otp'}
          style={{
            width: '50%',
            marginLeft: '35px',
            marginTop: '35px'
          }} />
        </div>

            <p
              style={{
                color: '#004995',
                fontSize: '20px',
                fontWeight: 'bold',
                marginTop: '15px',
                textAlign: 'center'
              }}
            >VERIFY OTP</p>
            <OtpInput inputStyles={{display: 'block', margin: 'auto'}} value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false}  />

            <Button
              variant="contained"
              onClick={() => {
                setOpen(false)
              }}
              style={{
                size: "large",
                display: "block",
                borderRadius: "25px",
                margin: 'auto',
                marginTop: '30px',
                height: '50px',
                backgroundColor: '#004995'
              }}
              fullWidth
            >
              <text
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >VERIFY</text>
            </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default Gate;
