import React from 'react'
import { Link } from 'react-router-dom'

function FleetNotAvailble() {
  return (
    <div className='overlay'>
        <div className='Fleetnotavaible-page-wrapper'>
            <div className='Serching-page-logowrapper'>
            <img className="searching-page-logo-image-class" src={require('../assets/images/logo-color.png')}/>
            
            </div>
             <div className='fleetnotavailable-page-content-wrapper'> 
                <div className='fleetnotavailable-page-content-wrapper-inner'>
                  <img src={require('../assets/images/sorry.png')} className="searching-page-content-image-class" />
                  <div className='fleetnotavailable-page-content-text'> Ottobot is out for delivery, will be reaching you by 25 mins then we can resume or service</div>
                  <div> <h2 className='fleetnotavailable-page-content-text-two'> <Link style={{color: 'rgb(0,73,148)'}} to="/getnotify">Get Notify </Link></h2></div>
                </div>
            </div> 
            <div
          style={{
            textAlign: 'center',
            bottom: '0px',
            color:'black',
            margin:'18px'
          }}
        >
          Powered by<br />
          <img
            src={require('../assets/images/ottonomy-logo-b.png')}
            alt={'logo'}
            className="store-logo"
            style={{
              // display: "block",
              // marginLeft: 'auto',
              // marginRight: 'auto',
              width: '50%'
            }} />
        </div>     
        </div>
        </div>
  )
}

export default FleetNotAvailble