import React, {useState} from "react";
import  { useNavigate } from 'react-router-dom';
import { sendMessage } from "../api/actions";
import '../assets/css/App.css';

import { TextField, Button, MenuItem, FormGroup, FormControlLabel, Checkbox, Modal, Typography, Box } from '@mui/material';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'

function Form() {

  const [open, setOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  // const msg = () => {
  //   sendMessage({
  //     "number": "+919678630459",
  //     "message": 'Your OTP verification code is: 12345'
  //   }).then((response) => {
  //     console.log(response);
  //     alert('0');
  //   }).catch((error) => {
  //     alert('1');
  //   });
  // }

  const handleOtp = (event) => {
    setOtp(event.target.value);
  }

  const verifyOtp = () => {
    if (otp === '12345')
      alert('Verified');
    else
      alert('Incorrect');
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="overlay">
      
      <div className="inner-base">
    <div className="logo"><img src={require('../assets/images/logo.png')} alt="logo" /></div>
    <div className="searching-ottobot">
        <h1>Hi, Good Morning</h1>
        <div className="form">
            <div className="form-listing"><input value="" type="text" placeholder="Flight No." /></div>
            <div className="form-listing">
                  <div className="btn-group dropdown-select" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                    <button type="button" className="btn btn-outline-primary" ngbDropdownToggle>Delivery Gate</button>
                    <div className="dropdown-menu" ngbDropdownMenu>
                      <button ngbDropdownItem>One</button>
                      <button ngbDropdownItem>Two</button>
                      <button ngbDropdownItem>Four!</button>
                    </div>
                  </div>
            </div>
            <div className="form-listing"><input value="" type="text" placeholder="Time" /></div>
            <div className="form-listing">
                <div className="btn-group dropdown-select" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                    <button type="button" className="btn btn-outline-primary" ngbDropdownToggle>Delivery Time</button>
                    <div className="dropdown-menu" ngbDropdownMenu>
                      <button ngbDropdownItem>One</button>
                      <button ngbDropdownItem>Two</button>
                      <button ngbDropdownItem>Four!</button>
                    </div>
                  </div>
            </div>
            <div className="form-listing"><input value="" type="email" placeholder="Email" /></div>
            <div className="form-listing"><input value="" type="number" placeholder="Phone Number" /></div>
            <div className="form-listing"><input type="button" value="Submit" /></div>
        </div>
    </div>
    <div className="powered">Powered by<img src={require('../assets/images/ottonomy-logo-b.png')} alt="logo" /></div>
</div>




      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Verify Otp
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            
            <TextField
              id="standard-size-normal"
              fullWidth
              value={otp}
              onChange={handleOtp}
              label="OTP"
              variant="standard" />

            <Button
              variant="contained"
              onClick={() => {
                verifyOtp()
              }}
              style={{
                size: "large",
                display: "block",
                borderRadius: "20px",
                marginTop: '20px'
              }}
            >
              <text
                style={{
                  fontSize: '15px',
                  fontWeight: 'bold'
                }}
              >VERIFY</text>
            </Button>

          </Typography>
        </Box>
      </Modal>
      
      </div>
  );
}

export default Form;
