import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import './assets/css/index.css';
import Home from './components/Home';
import Gate from './components/Gate';
import Fleet from './components/Fleet';
import Order from './components/Order';
import Form from './components/Form'
import Searching from './components/Searching';
import FleetNotAvailble from './components/FleetNotAvailble';
import Getnotify from './components/Getnotify';
import { Provider } from 'react-redux';
import store from './redux/store';
import Error from './components/Error';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route exact path={'/'} element={ <Home/> } />
            <Route exact path={'/fleetnotavaible'} element={ <FleetNotAvailble/> } />
            <Route exact path={'/getnotify'} element={ <Getnotify/> } />
            {/* <Route exact path={'/home'} element={ <Home/> } /> */}
            <Route exact path={'/gate'} element={ <Gate/> } />
            <Route exact path={'/fleet'} element={ <Fleet/> } />
            <Route exact path={'/order'} element={ <Order/> } />
            <Route exact path={'/form'} element={ <Form/> } />
            <Route exact path={'/Error'} element={ <Error/> } />
          </Routes>
        </BrowserRouter>
        </Provider> 
     </React.StrictMode>
);

