export const storeOrdderid =(data)=>{
    return{
        type: "STORE_ORDER_ID",
        payload:data
    }
}
export const storedroplocation =(data)=>{
    return{
        type: "STORE_DROP_LOCATION",
        payload:data
    }
}

