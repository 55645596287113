import { createStore,combineReducers } from 'redux'
import {StoreOrderId,StoreDropLocation} from './Reducers'

const mutipleReducers = combineReducers({
    StoreOrderId,
    StoreDropLocation
})

const store = createStore(mutipleReducers);

export default store